import {
  ANALYZED_COMPARISONS,
  AUTOCOMPLETE_CV_SEARCH,
  CV_JOB_LOADING,
  FETCH_COMPARE_GAP,
  FETCH_SKILLS_GROUP,
  FETCH_SKILLS_GROUP_LOADING,
  POST_NEW_JOB,
  SET_LISTING,
  SKILL_GAP_LIST,
  UPLOAD_RESUME_AND_FETCH_SKILLS
} from "@/store/modules/cv_job_matching/constants";
import { Commit } from "vuex";
import {
  get_analyzed_comparisons,
  get_extracted_cv_skills,
  get_search_job,
  post_new_job,
  post_search_job,
  search_resume,
  skill_gap_list,
  upload_resume_file
} from "@/store/apis/skill";
import { fetch_skills_group } from "@/store/apis/user";
import { SET_ERROR } from "@/store";
import { ExtractSkillsResponse } from "@/interfaces/responses/extract_skills";
import { JobsWithSkillsGroup } from "@/interfaces/data_objects/JobsWithSkillsGroup";
import {
  FetchCompareGapPayload,
  SkillGapListPayload
} from "@/store/modules/cv_job_matching/interfaces";
import { CompareGapResponse } from "@/interfaces/responses/compare_gap";

export default {
  [AUTOCOMPLETE_CV_SEARCH]: async (
    { commit }: { commit: Commit },
    cv_name: string
  ): Promise<void> => {
    try {
      commit(CV_JOB_LOADING, true);
      const response = await search_resume(cv_name);
      commit(CV_JOB_LOADING, false);
      return response.data.data;
    } catch (e) {
      commit(CV_JOB_LOADING, false);
    }
  },
  /**
   * Fetch skills group => payload: skills: string[]
   */
  [FETCH_SKILLS_GROUP]: async (
    { commit }: { commit: Commit },
    payload: string[]
  ): Promise<any> => {
    try {
      commit(FETCH_SKILLS_GROUP_LOADING, true);
      const obj = {
        skills: payload
      };
      const form_data = new FormData();
      form_data.append("skills", payload as never);
      const response = await fetch_skills_group(obj);
      commit(FETCH_SKILLS_GROUP_LOADING, false);
      return response.data.data.skill_group;
    } catch (e) {
      commit(FETCH_SKILLS_GROUP_LOADING, false);
      return null;
    }
  },
  [UPLOAD_RESUME_AND_FETCH_SKILLS]: async (
    { commit }: { commit: Commit },
    payload: FormData
  ): Promise<ExtractSkillsResponse | null> => {
    try {
      //
      const post_response = await upload_resume_file(payload);
      if (post_response && post_response.data.response_id) {
        const response_id = post_response.data.response_id;
        // Get call for fetching cv skills
        let count = 0;
        let success = false;
        let final_response: ExtractSkillsResponse | null = null;
        while (!success) {
          const response = await get_extracted_cv_skills(response_id);
          // If successful response
          if (response.status !== 202) {
            success = true;
            final_response = response.data.data as ExtractSkillsResponse;
          }
          if (!success) {
            count = count + 1;
            // Wait 3 seconds
            await new Promise((resolve) => {
              setTimeout(resolve, 3000);
            });
          }
          // If server not respond after 100 retries
          if (count === 100) {
            commit(
              SET_ERROR,
              "Server is not responding. Please try again later.",
              { root: true }
            );
            break;
          }
        }
        return final_response;
      } else return null;
    } catch (e) {
      commit(SET_ERROR, e, { root: true });
      return null;
    }
  },
  [POST_NEW_JOB]: async (
    { commit }: { commit: Commit },
    payload: FormData
  ): Promise<null | JobsWithSkillsGroup> => {
    try {
      const response = await post_new_job(payload);
      return response.data.data;
    } catch (e) {
      commit(SET_ERROR, e, { root: true });
      return null;
    }
  },
  [FETCH_COMPARE_GAP]: async (
    { commit }: { commit: Commit },
    payload: FetchCompareGapPayload
  ): Promise<CompareGapResponse | null> => {
    try {
      const response = await post_search_job(payload);
      const compare_id = response.data.compare_id;
      if (!compare_id) return null;
      let success = false;
      let count = 0;
      let final_response = null;
      while (!success) {
        // GET call to get searched job data
        const response = await get_search_job(compare_id);
        if (response.status !== 202) {
          success = true;
          const data = response.data.data.data;
          if (data.length <= 0) break;
          else {
            const final_data = data[0].cvs;
            if (final_data.length > 0) final_response = final_data[0];
            else break;
          }
        }
        if (!success) {
          count = count + 1;
          await new Promise((resolve) => setTimeout(resolve, 3000));
        }
        if (count === 50) {
          commit(
            SET_ERROR,
            "Server is not responding. Please try again later.",
            { root: true }
          );
          break;
        }
      }
      return final_response;
    } catch (e) {
      commit(SET_ERROR, e, { root: true });
      return null;
    }
  },
  [ANALYZED_COMPARISONS]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    {
      item_per_page,
      page_number
    }: {
      item_per_page: number;
      page_number: number;
    }
  ): Promise<void> => {
    try {
      commit(CV_JOB_LOADING, true);
      const response = await get_analyzed_comparisons(
        page_number,
        item_per_page
      );
      commit(SET_LISTING, response.data);
      commit(CV_JOB_LOADING, false);
    } catch (e) {
      commit(CV_JOB_LOADING, false);
      commit(SET_ERROR, e, { root: true });
      commit(SET_LISTING, null);
    }
  },
  [SKILL_GAP_LIST]: async (
    { commit }: { commit: Commit },
    payload: SkillGapListPayload
  ): Promise<null | CompareGapResponse> => {
    try {
      const response = await skill_gap_list(payload);
      return response.data.data;
    } catch (e) {
      commit(SET_ERROR, e, { root: true });
      return null;
    }
  }
};
