import {
  CV_JOB_ANALYSIS_PAYLOAD,
  CV_JOB_LOADING,
  FETCH_SKILLS_GROUP_LOADING,
  SET_LISTING
} from "@/store/modules/cv_job_matching/constants";
import {
  CvJobAnalysis,
  CvJobMatchingListingResponse,
  CvJobMatchingState
} from "@/store/modules/cv_job_matching/interfaces";

export default {
  /**
   * Set state loading
   * @param state
   * @param {boolean} payload: loading
   */
  [CV_JOB_LOADING]: (state: CvJobMatchingState, payload: boolean): void => {
    state.loading = payload;
  },
  /**
   * Set skill group loading
   * @param state
   * @param {boolean} payload: loading
   */
  [FETCH_SKILLS_GROUP_LOADING]: (
    state: CvJobMatchingState,
    payload: boolean
  ): void => {
    state.skill_group_loading = payload;
  },
  [CV_JOB_ANALYSIS_PAYLOAD]: (
    state: CvJobMatchingState,
    payload: CvJobAnalysis
  ): void => {
    state.cv_job_analysis = payload;
  },
  [SET_LISTING]: (
    state: CvJobMatchingState,
    payload: CvJobMatchingListingResponse | null
  ) => {
    state.cv_job_analysis.listing = payload;
  }
};
