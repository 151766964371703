import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { skill_extractor_routes } from "@/router/skill_extractor_routes";
import { global_valley_routes } from "@/router/global_valley_routes";
import store from "@/store";
import { ACTION_LOGOUT } from "@/store/modules/auth";
import { cv_job_matching_routes } from "@/router/cv_job_matching";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/Login.vue")
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/Register.vue")
  },
  {
    path: "/verify-email/:token",
    name: "verify-email",
    component: () => import("../views/VerifyEmail.vue")
  },
  // Individual routes
  {
    path: "/dashboard",
    component: () => import("@/views/Dashboard.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("@/views/Home.vue")
      },
      skill_extractor_routes,
      global_valley_routes,
      cv_job_matching_routes,
      {
        path: "/organization-dashboard",
        name: "organization-dashboard",
        component: () => import("../views/organization/Dashboard.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/skill-extractor-old",
        name: "skill-extractor-old",
        component: () => import("../views/SkillExtractorOld.vue")
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("../views/Profile.vue")
      }
    ]
  },
  {
    path: "*",
    component: () => import("../views/NotFound.vue")
  }
];

const router = new VueRouter({
  routes
});

const default_title = "HCMS.ai";

router.beforeEach(async (to, from, next) => {
  const loggedIn = localStorage.getItem("token");
  const url_name = to.name;

  let user: any = localStorage.getItem("user");
  if (loggedIn && !user) {
    await store.dispatch(`auth/${ACTION_LOGOUT}`);
    next("/");
  } else user = JSON.parse(<string>user);
  if (
    user &&
    user.company_id === 20 &&
    loggedIn &&
    !to.fullPath.includes("global-valley")
  )
    next("/global-valley/resume-parser");
  else if (
    (url_name === "login" || to.name === "register") &&
    loggedIn &&
    user
  ) {
    next("dashboard");
  } else if (url_name === "home" && loggedIn && user) {
    next("dashboard");
  }

  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next("/");
  }
  let title = "";
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (url_name) title = url_name.replace(/-/g, " ").toUpperCase();
  Vue.nextTick(() => {
    document.title = `${title} | ${default_title}` || default_title;
  });
  next();
});

export default router;
