



















import Vue from "vue";

export default Vue.extend({
  name: "Snackbar",
  /**
   * Reactive properties for Snackbar component
   * */
  data: () => ({
    snackbar_temp: Boolean(false)
  }),
  /**
   * as a child component receving props from parent component
   * */
  props: {
    snackbar: {
      type: Boolean
    },
    text: {
      type: String
    },
    timeout: {
      type: Number,
      default: -1
    }
  },
  /**
   * keeping an eye on change in props properties to update local reactive property
   * */
  watch: {
    snackbar(value: boolean) {
      if (value) {
        this.snackbar_temp = value;
        /* setTimeout(() => {
          this.$emit("snackBarClick", false);
        }, this.timeout);*/
      } else {
        //Do Nothing
      }
    }
  },
  /**
   * decide to show the snackbar when the component load
   * */
  mounted(): void {
    this.snackbar_temp = this.snackbar;
  },
  methods: {
    /**
     * on clicking x button hide the snack bar
     * */
    snackbarClick() {
      this.snackbar_temp = false;
      this.$emit("snackBarClick", false);
    }
  }
});
