export const GET_JOB_PARSER_LOADING = "GET_JOB_PARSER_LOADING";
export const SET_JOB_PARSER_LOADING = "SET_JOB_PARSER_LOADING";
export const GET_JOBS_LISTING_LOADING = "GET_JOBS_LISTING_LOADING";
export const SET_JOBS_LISTING_LOADING = "SET_JOBS_LISTING_LOADING";

export const POST_JOB = "POST_JOB";

export const GET_POST_JOB_LOADING = "GET_POST_JOB_LOADING";
export const SET_POST_JOB_LOADING = "SET_POST_JOB_LOADING";

export const GET_JOBS_LISTING = "GET_JOBS_LISTING";
export const SET_JOBS = "SET_JOBS";

export const REANALYSIS_JOB = "REANALYSIS_JOB";
export const GET_REANALYSIS_JOB_LOADING = "GET_REANALYSIS_JOB_LOADING";
export const SET_REANALYSIS_JOB_LOADING = "SET_REANALYSIS_JOB_LOADING";

export const REANALYSIS_JOB_ID = "REANALYSIS_JOB_ID";
export const REMOVE_REANALYSIS_JOB_ID = "REMOVE_REANALYSIS_JOB_ID";
export const ADD_REANALYSIS_JOB_ID = "ADD_REANALYSIS_JOB_ID";

export const UPDATE_JOBS_VALUE = "UPDATE_JOBS_VALUE";
