import {
  GET_JOB_PARSER_LOADING,
  GET_JOBS_LISTING,
  GET_JOBS_LISTING_LOADING,
  GET_POST_JOB_LOADING,
  GET_REANALYSIS_JOB_LOADING,
  REANALYSIS_JOB_ID
} from "@/store/modules/job_parser/constants";
import {
  JobParserState,
  JobsData
} from "@/store/modules/job_parser/interfaces";

export default {
  [GET_JOB_PARSER_LOADING]: (state: JobParserState): boolean =>
    state.job_parser_loading,
  [GET_JOBS_LISTING_LOADING]: (state: JobParserState): boolean =>
    state.job_listing_loading,
  [GET_POST_JOB_LOADING]: (state: JobParserState): boolean =>
    state.post_job_loading,
  [GET_REANALYSIS_JOB_LOADING]: (state: JobParserState): boolean =>
    state.reanalysis_job_loading,
  [GET_JOBS_LISTING]: (state: JobParserState): JobsData => state.jobs,
  [REANALYSIS_JOB_ID]: (state: JobParserState): number[] =>
    state.reanalysis_job_id
};
