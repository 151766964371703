import { RouteConfig } from "vue-router";

export const cv_job_matching_routes: RouteConfig = {
  path: "/matching",
  component: () => import("@/views/cv_job_matching/Index.vue"),
  children: [
    {
      path: "",
      redirect: {
        name: "matching-list"
      }
    },
    {
      path: "list",
      name: "matching-list",
      component: () => import("@/views/cv_job_matching/List.vue"),
      meta: { transition: "slide-right" }
    },
    {
      path: "new",
      name: "match-job-cv",
      component: () => import("@/views/cv_job_matching/SelectCvJob.vue"),
      meta: { transition: "slide-right" }
    },
    {
      path: "new/analysis",
      name: "cv-job-analysis",
      component: () => import("@/views/cv_job_matching/CvJobAnalysis.vue"),
      meta: { transition: "slide-right" }
    }
  ]
};
