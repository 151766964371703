import ErrorService from "@/services/ErrorService";
import axios, { AxiosRequestConfig } from "axios";
/**
 * axios interceptor for binding the access token with api call request
 * */
export const tokenInterceptor = function (): any {
  axios.interceptors.request.use(
    function (config: AxiosRequestConfig) {
      const token = localStorage.getItem("token");
      if (token && config.headers) {
        config.headers.token = `${token}`;
      } else {
        // DO Nothing
      }
      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );
};
/**
 * axios interceptor for noticing error relevent to the api calling
 * */
export const errorInterceptor = function (): any {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error): any {
      const data = error.response;
      ErrorService.onError(error.toString(), "VUE");
      return Promise.reject(error);
    }
  );
};
