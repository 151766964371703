import {
  ADD_REANALYSIS_JOB_ID,
  REMOVE_REANALYSIS_JOB_ID,
  SET_JOB_PARSER_LOADING,
  SET_JOBS,
  SET_JOBS_LISTING_LOADING,
  SET_POST_JOB_LOADING,
  SET_REANALYSIS_JOB_LOADING,
  UPDATE_JOBS_VALUE
} from "@/store/modules/job_parser/constants";
import {
  JobParserState,
  JobsData
} from "@/store/modules/job_parser/interfaces";

export default {
  [SET_JOB_PARSER_LOADING]: (state: JobParserState, payload: boolean): void => {
    state.job_parser_loading = payload;
  },
  [SET_JOBS_LISTING_LOADING]: (
    state: JobParserState,
    payload: boolean
  ): void => {
    state.job_listing_loading = payload;
  },
  [SET_REANALYSIS_JOB_LOADING]: (
    state: JobParserState,
    payload: boolean
  ): void => {
    state.reanalysis_job_loading = payload;
  },
  [SET_POST_JOB_LOADING]: (state: JobParserState, payload: boolean): void => {
    state.post_job_loading = payload;
  },
  [SET_JOBS]: (state: JobParserState, payload: JobsData): void => {
    state.jobs = payload;
  },
  [ADD_REANALYSIS_JOB_ID]: (state: JobParserState, payload: number): void => {
    state.reanalysis_job_id.push(payload);
  },
  [REMOVE_REANALYSIS_JOB_ID]: (
    state: JobParserState,
    payload: number
  ): void => {
    state.reanalysis_job_id = state.reanalysis_job_id.filter(
      (id) => id !== payload
    );
  },
  [UPDATE_JOBS_VALUE]: (
    state: JobParserState,
    { jid, link }: { jid: number; link: string }
  ): void => {
    const index = state.jobs.jobs.findIndex((job) => job.jid === jid);
    state.jobs.jobs[index].resume_book_link = link;
  }
};
