import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#1C53F4",
        "primary-dark": "#2C32A9",
        "primary-light": "#33BFFF",
        danger: "#FF4066",
        "danger-light": "#FF8473",
        success: "#3CE17E",
        info: "#2DFFF5",
        warning: "#FFB815",
        pink: "#EE49FD",
        white: "#ffffff",
        dark: "#000000",
        "dark-darker": "#0F1322",
        grey: "#858D98",
        "light-bg": "#F1F5FE",
        background: "#FAFCFF",
        slider: "#3fd1c9"
      }
    }
  }
});

export default vuetify;
