import {
  GET_TOP_JOB_SECTORS,
  GET_UPSKILL_COURSES,
  RESET_STATE,
  SET_CV_ID,
  SET_CV_SKILLS,
  SET_CV_SKILLS_LOADING,
  SET_TOP_JOB_ID,
  SET_TOP_JOB_SECTORS,
  SET_SKILL_EXTRACTOR_ERROR,
  SET_SKILL_EXTRACTOR_ERROR_MSG,
  SET_TOP_JOB_SECTORS_LOADING,
  SET_UPSKILL_COURSES,
  SET_UPSKILL_COURSES_LOADING,
  UPLOAD_CV_AND_GET_SKILLS,
  CANCEL_ANALYZING,
  SET_CANCEL_ANALYZING,
  SEARCH_JOBS,
  SECTOR_EXIST,
  JOB_EXIST,
  GETTER_TOP_JOB_SECTORS,
  SEARCH_SECTORS,
  SET_SEARCH_JOB_LOADING,
  GET_SEARCHED_JOB_DATA,
  GET_ANALYZED_RESUMES,
  SET_ANALYZED_RESUMES,
  FETCH_ANALYZED_RESUME,
  SET_UPLOAD_VIEW_RESUME,
  REMOVE_ANALYZED_RESUME
} from "@/store/modules/skill_extractor/constants";
import { Commit } from "vuex";
import {
  get_analyzed_resumes,
  get_extracted_cv_skills,
  get_search_job,
  get_sector_top_jobs,
  get_upskill_courses,
  job_search,
  post_search_job,
  post_sector_top_jobs,
  post_upskill_courses,
  remove_analyzed_resumes,
  sector_search,
  upload_resume_file,
  upload_resume_text
} from "@/store/apis/skill";
import { SET_ERROR, SET_NOTIFICATION } from "@/store";
import router from "@/router";

export default {
  [UPLOAD_CV_AND_GET_SKILLS]: async (
    { commit, getters }: { commit: Commit; getters: any },
    payload: any
  ) => {
    commit(SET_CV_SKILLS_LOADING, true);
    commit(SET_CANCEL_ANALYZING, false);
    let response = null;
    try {
      if (payload.type === "file")
        response = await upload_resume_file(payload.data);
      else response = await upload_resume_text(payload.data);
      const cv_id = response.data.response_id;
      // const cv_id = "8556678e-7c5e-11ed-b6d3-0242ac130003";
      if (cv_id) {
        commit(SET_CV_ID, cv_id);
        let count = 0;
        let success = false;
        while (!success) {
          // If user cancel analyzing break loop
          if (getters.GET_CANCEL_ANALYZING) break;
          const response = await get_extracted_cv_skills(cv_id);
          // If successful response
          if (response.status !== 202) {
            success = true;
            commit(SET_CV_SKILLS, response.data.data);
          }
          if (!success) {
            count = count + 1;
            // Wait 3 seconds
            await new Promise((resolve) => {
              setTimeout(resolve, 3000);
            });
          }
          // If server not respond after 100 retries
          if (count === 100) {
            commit(SET_CV_ID, "");
            commit(SET_CV_SKILLS, "");
            commit(SET_SKILL_EXTRACTOR_ERROR, true);
            commit(
              SET_SKILL_EXTRACTOR_ERROR_MSG,
              "Server is not responding. Please try again later."
            );
            break;
          }
        }
        // If user cancel analyzing
        if (getters.GET_CANCEL_ANALYZING) {
          commit(SET_CV_ID, "");
          commit(SET_CV_SKILLS, "");
          commit(SET_CANCEL_ANALYZING, false);
          return;
        }
      } else {
        commit(SET_SKILL_EXTRACTOR_ERROR, true);
        commit(SET_SKILL_EXTRACTOR_ERROR_MSG, "response_id is missing");
      }
      commit(SET_CV_SKILLS_LOADING, false);
    } catch (e: any) {
      let err_msg = "Some error has occurred. Please try again later.";
      if (e.response) err_msg = e.response.data.message;
      // Setting error
      commit(SET_SKILL_EXTRACTOR_ERROR, true);
      commit(SET_SKILL_EXTRACTOR_ERROR_MSG, err_msg);
      // Resetting states
      commit(SET_CV_SKILLS_LOADING, false);
      commit(SET_CV_ID, "");
      commit(SET_CV_SKILLS, "");
      commit(SET_CANCEL_ANALYZING, false);
    }
  },
  [GET_TOP_JOB_SECTORS]: async (
    { commit }: { commit: Commit },
    cv_id: string
  ): Promise<void> => {
    commit(SET_TOP_JOB_SECTORS_LOADING, true);
    try {
      const response = await post_sector_top_jobs(cv_id);
      const top_job_id = response.data.topjob_id;
      // const top_job_id = "f1217b64-8f7a-11ed-b4e9-0242ac130003";
      if (top_job_id) {
        commit(SET_TOP_JOB_ID, top_job_id);
        let success = false;
        let count = 0;
        while (!success) {
          const response = await get_sector_top_jobs(top_job_id);
          if (response.status !== 202) {
            success = true;
            commit(SET_TOP_JOB_SECTORS, response.data.data);
          }
          if (!success) {
            count = count + 1;
            await new Promise((resolve) => setTimeout(resolve, 3000));
          }
          if (count === 100) {
            commit(SET_TOP_JOB_ID, "");
            commit(SET_TOP_JOB_SECTORS, "");
            commit(SET_SKILL_EXTRACTOR_ERROR, true);
            commit(
              SET_SKILL_EXTRACTOR_ERROR_MSG,
              "Server is not responding. Please try again later."
            );
            break;
          }
        }
      } else {
        commit(SET_SKILL_EXTRACTOR_ERROR, true);
        commit(SET_SKILL_EXTRACTOR_ERROR_MSG, "Top jobs are missing");
      }
      commit(SET_TOP_JOB_SECTORS_LOADING, false);
    } catch (e: any) {
      let err_msg = "Some error has occurred. Please try again later.";
      if (e.response) err_msg = e.response.data.message;
      // Set error
      commit(SET_SKILL_EXTRACTOR_ERROR, true);
      commit(SET_SKILL_EXTRACTOR_ERROR_MSG, err_msg);
      // Reset state
      commit(SET_TOP_JOB_SECTORS_LOADING, false);
      commit(SET_TOP_JOB_ID, "");
      commit(SET_TOP_JOB_SECTORS, "");
    }
  },
  [GET_UPSKILL_COURSES]: async (
    { commit }: { commit: Commit },
    payload: any
  ): Promise<void> => {
    commit(SET_UPSKILL_COURSES_LOADING, true);
    try {
      const response = await post_upskill_courses(payload);
      if (!response.data.course_id) {
        commit(SET_UPSKILL_COURSES_LOADING, false);
        commit(SET_UPSKILL_COURSES, response.data);
        return;
      }
      const cv_upskill_course_id = response.data.course_id;
      // const cv_upskill_course_id = "f45afd92-8dcb-11ed-8781-0242ac130003";
      if (cv_upskill_course_id) {
        let success = false;
        let count = 0;
        let upskill_courses = null;
        while (!success) {
          // Get CV upskill courses
          const response = await get_upskill_courses(cv_upskill_course_id);
          if (response.status !== 202) {
            success = true;
            upskill_courses = response.data.data;
          }
          if (count === 50) {
            // Set global error
            commit(
              SET_ERROR,
              {
                response: {
                  data: {
                    message: "Server is not responding. Please try again later."
                  }
                }
              },
              { root: true }
            );
            break;
          }
          if (!success) {
            count = count + 1;
            await new Promise((resolve) => setTimeout(resolve, 3000));
          }
        }
        if (upskill_courses) commit(SET_UPSKILL_COURSES, upskill_courses);
        else {
          // Set global error
          commit(
            SET_ERROR,
            {
              response: {
                data: { message: "No Courses Found" }
              }
            },
            { root: true }
          );
        }
      }
      commit(SET_UPSKILL_COURSES_LOADING, false);
    } catch (e: any) {
      commit(SET_UPSKILL_COURSES_LOADING, false);
      commit(SET_UPSKILL_COURSES, "");
      commit(SET_ERROR, e, { root: true });
    }
  },
  [CANCEL_ANALYZING]: (
    { commit }: { commit: Commit },
    payload: boolean
  ): void => {
    commit(SET_CANCEL_ANALYZING, payload);
  },
  [GET_SEARCHED_JOB_DATA]: async (
    { commit }: { commit: Commit },
    payload: any
  ): Promise<any> => {
    try {
      commit(SET_SEARCH_JOB_LOADING, true);
      const response = await post_search_job(payload);
      if (response.data.response) {
        commit(SET_SEARCH_JOB_LOADING, false);
        return response.data.response.data[0].cvs[0];
      }
      const compare_id = response.data.compare_id;
      let success = false;
      let count = 0;
      let job_data = null;
      while (!success) {
        // GET call to get searched job data
        const response = await get_search_job(compare_id);
        if (response.status !== 202) {
          success = true;
          job_data = response.data.data.data[0].cvs;
        }
        if (!success) {
          count = count + 1;
          await new Promise((resolve) => setTimeout(resolve, 3000));
        }
        if (count === 50) {
          commit(SET_SEARCH_JOB_LOADING, false);
          commit(
            SET_NOTIFICATION,
            "Server is not responding. Please try again later.",
            { root: true }
          );
          break;
        }
      }
      commit(SET_SEARCH_JOB_LOADING, false);
      if (success) return job_data[0];
    } catch (e: any) {
      commit(SET_ERROR, e, { root: true });
      commit(SET_SEARCH_JOB_LOADING, false);
    }
  },
  [SEARCH_JOBS]: async (
    { commit }: { commit: Commit },
    payload: string
  ): Promise<any> => {
    try {
      const response = await job_search(payload);
      return response.data;
    } catch (e: any) {
      commit(SET_ERROR, e, { root: true });
    }
  },
  [SEARCH_SECTORS]: async (
    { commit }: { commit: Commit },
    payload: string
  ): Promise<any> => {
    try {
      const response = await sector_search(payload);
      return response.data;
    } catch (e: any) {
      commit(SET_ERROR, e, { root: true });
    }
  },

  // Extra
  [SECTOR_EXIST]: (
    {
      commit,
      getters
    }: {
      commit: Commit;
      getters: any;
    },
    sector_name: string
  ): boolean => {
    try {
      const sector = sector_name.toLowerCase().trim();
      const sectors: Array<any> = getters[GETTER_TOP_JOB_SECTORS];
      const exist = sectors.find(
        (val) => val.name.toLowerCase().trim() === sector
      );
      if (exist) return true;
      //
      return false;
    } catch (e: any) {
      commit(SET_ERROR, e, { root: true });
      return false;
    }
  },
  [JOB_EXIST]: (
    {
      commit,
      getters
    }: {
      commit: Commit;
      getters: any;
    },
    job: string
  ): any => {
    try {
      const job_name = job.toLowerCase().trim();
      let found = null;
      const sectors: Array<any> = getters[GETTER_TOP_JOB_SECTORS];
      for (const sector of sectors) {
        const exist = sector.top_jobs.find(
          (val: any) => val.job_title.toLowerCase().trim() === job_name
        );
        if (exist) {
          found = sector;
          break;
        }
      }
      return found;
    } catch (e: any) {
      commit(SET_ERROR, e, { root: true });
      return false;
    }
  },
  [GET_ANALYZED_RESUMES]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    {
      item_per_page,
      page_number,
      top_job = true
    }: {
      item_per_page: number;
      page_number: number;
      top_job: boolean;
    }
  ): Promise<void> => {
    try {
      const response = await get_analyzed_resumes(
        page_number,
        item_per_page,
        top_job
      );
      commit(SET_ANALYZED_RESUMES, response.data);
    } catch (e: any) {
      commit(SET_ERROR, e, { root: true });
      commit(SET_ANALYZED_RESUMES, {});
    }
  },
  [FETCH_ANALYZED_RESUME]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    upload_response_id: string
  ): Promise<void> => {
    try {
      await router.push("skill-extractor/resume-analysis"); // Push to extract skills page
      commit(SET_CV_SKILLS_LOADING, true);
      commit(SET_UPLOAD_VIEW_RESUME, true);
      commit(SET_CV_ID, upload_response_id);
      // Extract skills
      const response = await get_extracted_cv_skills(upload_response_id);
      commit(SET_CV_SKILLS, response.data.data);
      const topjob_id = response.data.data.topjob_id;
      commit(SET_CV_SKILLS_LOADING, false);
      commit(SET_TOP_JOB_SECTORS_LOADING, true);
      // Extract top jobs
      let count = 0;
      let success = false;
      while (!success) {
        const response = await get_sector_top_jobs(topjob_id);
        if (response.status !== 202) {
          success = true;
          commit(SET_TOP_JOB_SECTORS, response.data.data);
          commit(SET_TOP_JOB_ID, response.data.data.topjob_id);
          commit(SET_TOP_JOB_SECTORS_LOADING, false);
        }
        if (!success) {
          count = count + 1;
          await new Promise((resolve) => setTimeout(resolve, 3000));
        }
        if (count === 100) {
          commit(SET_CV_ID, "");
          commit(SET_CV_SKILLS, "");
          commit(SET_TOP_JOB_SECTORS_LOADING, false);
          commit(SET_SKILL_EXTRACTOR_ERROR, true);
          commit(
            SET_SKILL_EXTRACTOR_ERROR_MSG,
            "Server is not responding. Please try again later."
          );
          break;
        }
      }
    } catch (e: any) {
      let err_msg = "Some error has occurred. Please try again later.";
      if (e.response) err_msg = e.response.data.message;
      // Reset state
      commit(SET_CV_ID, "");
      commit(SET_CV_SKILLS_LOADING, false);
      commit(SET_CV_SKILLS, "");
      commit(SET_TOP_JOB_SECTORS_LOADING, false);
      commit(SET_TOP_JOB_SECTORS, "");
      commit(SET_TOP_JOB_ID, "");
      // Error
      commit(SET_SKILL_EXTRACTOR_ERROR, true);
      commit(SET_SKILL_EXTRACTOR_ERROR_MSG, err_msg);
    }
  },
  /**
   * Function to soft remove analyzed resume from DB & locally from store
   * @param commit
   * @param response_id
   */
  [REMOVE_ANALYZED_RESUME]: async (
    { commit }: { commit: Commit },
    response_id: string
  ): Promise<void> => {
    try {
      const response: any = await remove_analyzed_resumes(response_id);
      commit(SET_NOTIFICATION, response.message, { root: true });
      // Locally remove resume
      commit(REMOVE_ANALYZED_RESUME, response_id);
    } catch (e: any) {
      commit(SET_ERROR, e, { root: true });
    }
  },
  [RESET_STATE]: ({ commit }: { commit: Commit }): void => {
    commit(SET_CV_SKILLS_LOADING, false);
    commit(SET_TOP_JOB_SECTORS_LOADING, false);
    commit(SET_UPSKILL_COURSES_LOADING, false);
    commit(SET_SEARCH_JOB_LOADING, false);
    commit(SET_SKILL_EXTRACTOR_ERROR, false);
    commit(SET_SKILL_EXTRACTOR_ERROR_MSG, "");
    commit(SET_CV_ID, "");
    commit(SET_CV_SKILLS, "");
    commit(SET_TOP_JOB_ID, "");
    commit(SET_TOP_JOB_SECTORS, "");
    commit(SET_UPSKILL_COURSES, "");
    commit(SET_UPLOAD_VIEW_RESUME, false);
  }
};
