import getters from "@/store/modules/resume_parser/getters";
import mutations from "@/store/modules/resume_parser/mutations";
import actions from "@/store/modules/resume_parser/actions";
import { ResumeParserState } from "@/store/modules/resume_parser/interfaces";
export default {
  namespaced: true,
  state: (): ResumeParserState => ({
    resume_parser_loading: false,
    resume_listing_loading: false,
    post_resume_loading: false,
    remove_resume_loading: false,
    resumes: {
      count: 0,
      resumes: []
    },
    uploaded_resumes_ids:
      JSON.parse(sessionStorage.getItem("uploaded_resumes") as string) ?? []
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
