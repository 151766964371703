import { Commit } from "vuex";
import {
  cv_top_jobs,
  get_cv_skills,
  get_cv_top_jobs,
  upload_cv_text,
  search_job,
  compare_job_post,
  compare_job_get,
  cv_upskill_courses,
  get_cv_upskill_courses,
  upload_file
} from "@/store/apis/skill";

export const SET_LOADING = "SET_LOADING";
export const GETTER_LOADING = "GETTER_LOADING";

export const SET_SKILLS_LOADING = "SET_SKILLS_LOADING";
export const GETTER_SKILLS_LOADING = "GETTER_SKILLS_LOADING";

export const SET_JOBS_LOADING = "SET_JOBS_LOADING";
export const GETTER_JOBS_LOADING = "GETTER_JOBS_LOADING";

export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_CV = "UPLOAD_CV";

export const GET_CV_SKILLS = "GET_CV_SKILLS";
export const SET_CV_ID = "SET_CV_ID";
export const GETTER_CV_ID = "GETTER_CV_ID";

export const GET_CV_TOP_JOBS = "GET_CV_TOP_JOBS";
export const SET_CV_TOP_JOB_ID = "SET_CV_TOP_JOB_ID";
export const GET_CV_TOP_JOB_ID = "GET_CV_TOP_JOB_ID";
export const SET_CV_TOP_JOBS = "SET_CV_TOP_JOBS";
export const GETTER_CV_TOP_JOBS = "GETTER_CV_TOP_JOBS";

export const SET_CV_SKILLS = "SET_CV_SKILLS";
export const GETTER_CV_SKILLS = "GETTER_CV_SKILLS";

export const SET_ERROR = "SET_ERROR";
export const SET_NOTIFICATION = "SET_NOTIFICATION";

export const SEARCH_JOB = "SEARCH_JOB";
export const COMPARE_JOB = "COMPARE_JOB";
export const SET_SEARCH_JOB_ID = "SET_SEARCH_JOB_ID";
export const SET_COMPARE_RESP = "SET_COMPARE_RESP";
export const GETTER_COMPARE_RESP = "GETTER_COMPARE_RESP";

export const SET_UPSKILL_COURSES_LOADING = "SET_UPSKILL_COURSES_LOADING";
export const SET_CV_UPSKILL_COURSES = "SET_CV_UPSKILL_COURSES";
export const GET_CV_UPSKILL_COURSES = "GET_CV_UPSKILL_COURSES";
export const GETTER_CV_UPSKILL_COURSES = "GETTER_CV_UPSKILL_COURSES";
export const GETTER_UPSKILL_COURSES_LOADING = "GETTER_UPSKILL_COURSES_LOADING";

export interface Skill {
  cv_skills: any;
  cv_top_jobs: any;
  cv_upskill_courses: any;
  cv_id: string;
  cv_top_jobs_id: string;
  loading: boolean;
  skills_loading: boolean;
  jobs_loading: boolean;
  courses_loading: boolean;
  searched_job_id: number | null;
  compare_resp: any;
}

export const skill_extractor_old = {
  namespaced: true,
  /**
   * vuex state variable
   * */
  state: (): Skill => ({
    cv_skills: "",
    cv_top_jobs: "",
    cv_upskill_courses: "",
    cv_id: "",
    cv_top_jobs_id: "",
    loading: false,
    skills_loading: false,
    jobs_loading: false,
    courses_loading: false,
    searched_job_id: null,
    compare_resp: ""
  }),
  mutations: {
    [SET_LOADING]: (state: Skill, payload: boolean): void => {
      state.loading = payload;
    },
    [SET_SKILLS_LOADING]: (state: Skill, payload: boolean): void => {
      state.skills_loading = payload;
    },
    [SET_JOBS_LOADING]: (state: Skill, payload: boolean): void => {
      state.jobs_loading = payload;
    },
    [SET_CV_ID]: (state: Skill, payload: string): void => {
      state.cv_id = payload;
    },
    [SET_CV_TOP_JOB_ID]: (state: Skill, payload: string): void => {
      state.cv_top_jobs_id = payload;
    },
    [SET_CV_SKILLS]: (state: Skill, payload: any): void => {
      state.cv_skills = payload;
    },
    [SET_CV_TOP_JOBS]: (state: Skill, payload: any): void => {
      state.cv_top_jobs = payload;
    },
    [SET_COMPARE_RESP]: (state: Skill, payload: any): void => {
      state.compare_resp = payload;
    },
    [SET_UPSKILL_COURSES_LOADING]: (state: Skill, payload: any): void => {
      state.courses_loading = payload;
    },
    [SET_CV_UPSKILL_COURSES]: (state: Skill, payload: any): void => {
      state.cv_upskill_courses = payload;
    }
  },
  actions: {
    [UPLOAD_FILE]: async (
      {
        commit
      }: {
        commit: Commit;
      },
      data: any
    ): Promise<any> => {
      commit(SET_LOADING, true);
      commit(SET_ERROR, null, { root: true });
      try {
        // Upload File
        const response = await upload_file(data);
        commit(SET_SKILLS_LOADING, false);
        return response;
      } catch (e) {
        commit(SET_LOADING, false);
        commit(SET_ERROR, e, { root: true });
      }
    },
    [UPLOAD_CV]: async (
      {
        commit
      }: {
        commit: Commit;
      },
      payload: any
    ): Promise<void> => {
      commit(SET_SKILLS_LOADING, true);
      commit(SET_ERROR, null, { root: true });
      /* commit(SET_NOTIFICATION, "Extracting Skills. Please wait", {
        root: true
      });*/
      try {
        let response = null;
        if (payload.type === "file") {
          // Upload CV Text
          response = await upload_file(payload.data);
        } else {
          // Upload CV Text
          response = await upload_cv_text(payload.data);
        }
        console.log("response", response);
        const cv_id = response.data.response_id;
        commit(SET_CV_ID, cv_id);

        let cv_skills = null;
        let success = false;
        let count = 0;
        while (!success) {
          // Get CV Skills
          const response1 = await get_cv_skills(cv_id);
          if (response1.status !== 202) {
            success = true;
            cv_skills = response1.data.data;
          }
          if (!success) {
            count = count + 1;
            await new Promise((resolve) => setTimeout(resolve, 3000));
          }

          if (count === 50) {
            commit(
              SET_NOTIFICATION,
              "Server is not responding. Please try again later.",
              { root: true }
            );
            break;
          }
        }
        if (cv_skills) {
          commit(SET_CV_SKILLS, cv_skills);
        }
        commit(SET_SKILLS_LOADING, false);
      } catch (e) {
        commit(SET_SKILLS_LOADING, false);
        commit(SET_ERROR, e, { root: true });
      }
    },
    [GET_CV_TOP_JOBS]: async (
      {
        commit
      }: {
        commit: Commit;
      },
      cv_id: string
    ): Promise<void> => {
      commit(SET_JOBS_LOADING, true);
      commit(SET_ERROR, null, { root: true });
      /*commit(SET_NOTIFICATION, "Getting top jobs data. Please wait", {
        root: true
      });*/
      try {
        // Upload CV Top Jobs
        const response2 = await cv_top_jobs(cv_id);
        const cv_job_id = response2.data.topjob_id;
        commit(SET_CV_TOP_JOB_ID, cv_job_id);

        let top_jobs = null;
        let success = false;
        let count = 0;
        if (cv_job_id) {
          while (!success) {
            // Get CV top jobs
            const response1 = await get_cv_top_jobs(cv_job_id);
            if (response1.status !== 202) {
              success = true;
              top_jobs = response1.data.data;
            }
            if (!success) {
              count = count + 1;
              await new Promise((resolve) => setTimeout(resolve, 3000));
            }
            if (count === 50) {
              commit(
                SET_NOTIFICATION,
                "Server is not responding. Please try again later.",
                { root: true }
              );
              break;
            }
          }
        }
        if (top_jobs) {
          commit(SET_CV_TOP_JOBS, top_jobs);
        }
        commit(SET_JOBS_LOADING, false);
      } catch (e) {
        commit(SET_JOBS_LOADING, false);
        commit(SET_ERROR, e, { root: true });
      }
    },
    [SEARCH_JOB]: async (
      {
        commit
      }: {
        commit: Commit;
      },
      text: string
    ): Promise<any> => {
      commit(SET_LOADING, true);
      commit(SET_ERROR, null, { root: true });
      try {
        text = text.toLowerCase();
        const response = await search_job(text);
        commit(SET_LOADING, false);
        return response.data;
      } catch (e) {
        commit(SET_LOADING, false);
        commit(SET_ERROR, e, { root: true });
      }
    },
    [COMPARE_JOB]: async (
      {
        commit
      }: {
        commit: Commit;
      },
      payload: any
    ): Promise<any> => {
      commit(SET_LOADING, true);
      commit(SET_ERROR, null, { root: true });
      try {
        const response = await compare_job_post({
          response_id: payload.cv_id,
          job_index: payload.job_id
        });

        let job_compare = null;
        let success = false;
        let count = 0;
        if (response.data.compare_id) {
          while (!success) {
            // Get searched_jobs
            const response_2 = await compare_job_get(response.data.compare_id);
            if (response_2.status !== 202) {
              success = true;
              job_compare = response_2.data.data;
            }
            if (!success) {
              count = count + 1;
              await new Promise((resolve) => setTimeout(resolve, 3000));
            }
            if (count === 10) {
              commit(
                SET_NOTIFICATION,
                "Server is not responding. Please try again later.",
                { root: true }
              );
              break;
            }
          }
        }
        if (job_compare) {
          commit(SET_COMPARE_RESP, job_compare);
        }

        commit(SET_LOADING, false);
      } catch (e) {
        commit(SET_LOADING, false);
        commit(SET_ERROR, e, { root: true });
      }
    },
    [GET_CV_UPSKILL_COURSES]: async (
      {
        commit
      }: {
        commit: Commit;
      },
      payload: any
    ): Promise<void> => {
      commit(SET_UPSKILL_COURSES_LOADING, true);
      commit(SET_ERROR, null, { root: true });
      /*commit(SET_NOTIFICATION, "Getting upskill data. Please wait", {
        root: true
      });*/
      try {
        const response = await cv_upskill_courses(payload);
        const cv_upskill_courses_id = response.data.course_id;

        let upskill_courses = null;
        let success = false;
        let count = 0;
        if (cv_upskill_courses_id) {
          while (!success) {
            // Get CV upskill courses
            const response1 = await get_cv_upskill_courses(
              cv_upskill_courses_id
            );
            if (response1.status !== 202) {
              success = true;
              upskill_courses = response1.data.data;
            }
            if (!success) {
              count = count + 1;
              await new Promise((resolve) => setTimeout(resolve, 3000));
            }
            if (count === 10) {
              commit(
                SET_NOTIFICATION,
                "Server is not responding. Please try again later.",
                { root: true }
              );
              break;
            }
          }
        }
        if (upskill_courses) {
          commit(SET_CV_UPSKILL_COURSES, upskill_courses);
        }
        commit(SET_UPSKILL_COURSES_LOADING, false);

        commit(SET_NOTIFICATION, null, { root: true });
      } catch (e) {
        commit(SET_UPSKILL_COURSES_LOADING, false);
        commit(SET_ERROR, e, { root: true });
      }
    }
  },
  getters: {
    [GETTER_LOADING]: (state: Skill): any => {
      return state.loading;
    },
    [GET_CV_TOP_JOB_ID]: (state: Skill): any => {
      return state.cv_top_jobs_id;
    },
    [GETTER_SKILLS_LOADING]: (state: Skill): any => {
      return state.skills_loading;
    },
    [GETTER_JOBS_LOADING]: (state: Skill): any => {
      return state.jobs_loading;
    },
    [GETTER_CV_ID]: (state: Skill): any => {
      return state.cv_id;
    },
    [GETTER_CV_SKILLS]: (state: Skill): any => {
      return state.cv_skills ? state.cv_skills.skills : [];
    },
    [GETTER_CV_TOP_JOBS]: (state: Skill): any => {
      return state.cv_top_jobs && state.cv_top_jobs.topjob_response
        ? state.cv_top_jobs.topjob_response.top_jobs
        : [];
    },
    [GETTER_COMPARE_RESP]: (state: Skill): any => {
      return state.compare_resp ? state.compare_resp : null;
    },
    [GETTER_UPSKILL_COURSES_LOADING]: (state: Skill): any => {
      return state.courses_loading;
    },
    [GETTER_CV_UPSKILL_COURSES]: (state: Skill): any => {
      return state.cv_upskill_courses && state.cv_upskill_courses.course_result
        ? state.cv_upskill_courses.course_result
        : null;
    }
  }
};
