let base_url: string;

if (process.env.VUE_APP_BASE_URL) {
  base_url = process.env.VUE_APP_BASE_URL;
} else {
  base_url = "localhost";
}

export const BASE_URL = base_url;

export const LOGIN = base_url + "user/login";
export const REGISTER = base_url + "user/register";
export const VERIFY_EMAIL = base_url + "user/verifyEmail";
export const GET_ROLES = base_url + "user/roles/list";

// Skill Extractor
export const CV_TEXT_UPLOAD = base_url + "skill/textUpload";
export const UPLOAD_FILE = base_url + "skill/fileUpload";
export const GET_CV_SKILLS = base_url + "skill/response";
export const CV_TOP_JOBS = base_url + "skill/topJob";
export const CV_COURSES = base_url + "skill/courses";
export const CV_FILE_UPLOAD = base_url + "skill/fileUpload";
export const SEARCH_JOB = base_url + "skill/job/search?name=";
export const JOB_COMPARE = base_url + "skill/compare";
// New API endpoints
export const UPLOAD_RESUME_FILE = base_url + "skill/upload_resume_file";
export const UPLOAD_RESUME_TEXT = base_url + "skill/upload_resume_text";
export const GET_EXTRACTED_CV_SKILLS = base_url + "skill/extracted_skills";
export const SECTOR_TOP_JOBS = base_url + "skill/sector_top_jobs";
export const UPSKILL_COURSES = base_url + "skill/upskill_courses";
export const JOB_SEARCH = base_url + "skill/search/job?name=";
export const SECTOR_SEARCH = base_url + "skill/search/sector?name=";

export const GET_SEARCHED_JOB_DATA = base_url + "skill/search_job";
export const GET_ANALYZED_RESUMES = base_url + "skill/analyzed_resumes";
export const REMOVE_ANALYZED_RESUME =
  base_url + "skill/analyzed_resumes/remove";

export const SEARCH_RESUME = base_url + "skill/search/resume?name=";

export const FETCH_SKILLS_GROUP = base_url + "user/skill-groups";

export const POST_NEW_JOB = base_url + "skill/post-job";

export const GET_ANALYZED_COMPARISONS = base_url + "skill/analyzed-comparison";

export const FETCH_SKILL_GAP_LIST = base_url + "skill/skill-gap-list";
