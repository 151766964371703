






















import Vue from "vue";
import Snackbar from "@/components/shared/Snackbar.vue";
import { mapGetters, mapMutations } from "vuex";
import {
  GET_ERROR,
  GET_NOTIFICATION,
  SET_ERROR,
  SET_NOTIFICATION
} from "@/store";

export default Vue.extend({
  name: "App",
  components: { Snackbar },
  data: () => ({
    snackbar: false,
    snackbar_notification: false
  }),
  computed: {
    ...mapGetters({
      error: GET_ERROR,
      notification: GET_NOTIFICATION
    })
  },
  watch: {
    async error(value) {
      if (!this.snackbar && value) {
        this.snackbar = !!value;
        await new Promise((resolve) =>
          setTimeout(() => {
            this.snackbar = false;
            this.set_error(null);
            return resolve;
          }, 5000)
        );
      } else this.snackbar = false;
    },
    async notification(value) {
      if (!this.snackbar_notification && value) {
        this.snackbar_notification = !!value;
        await new Promise((resolve) =>
          setTimeout(() => {
            this.snackbar_notification = false;
            this.set_notification("");
            return resolve;
          }, 5000)
        );
      } else this.snackbar_notification = false;
    }
  },
  methods: {
    ...mapMutations({
      set_notification: SET_NOTIFICATION,
      set_error: SET_ERROR
    }),
    /**
     * hide the snackbar x button action
     * */
    snackBarClick() {
      this.snackbar = false;
      this.set_error(null);
    },
    /**
     * hide the snackbar x button action for notification
     * */
    snackBarNotiClick() {
      this.snackbar_notification = false;
      this.set_notification("");
    }
  }
});
