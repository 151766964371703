export const GET_RESUME_PARSER_LOADING = "GET_RESUME_PARSER_LOADING";
export const SET_RESUME_PARSER_LOADING = "SET_RESUME_PARSER_LOADING";
export const GET_RESUME_LISTING_LOADING = "GET_RESUME_LISTING_LOADING";
export const SET_RESUME_LISTING_LOADING = "SET_RESUME_LISTING_LOADING";

export const POST_RESUME = "POST_RESUME";

export const GET_POST_RESUME_LOADING = "GET_POST_RESUME_LOADING";
export const SET_POST_RESUME_LOADING = "SET_POST_RESUME_LOADING";

export const GET_RESUME_LISTING = "GET_RESUME_LISTING";
export const SET_RESUME = "SET_RESUME";

export const GET_UPLOADED_RESUMES_ID = "GET_UPLOADED_RESUMES_ID";
export const SET_UPLOADED_RESUMES_ID = "SET_UPLOADED_RESUMES_ID";
export const GET_UPLOADED_RESUMES_STATUS = "GET_UPLOADED_RESUMES_STATUS";

export const DELETE_UPLOADED_RESUME = "DELETE_UPLOADED_RESUME";
export const GET_DELETE_UPLOADED_RESUME_LOADING =
  "GET_DELETE_UPLOADED_RESUME_LOADING";
export const SET_DELETE_UPLOADED_RESUME_LOADING =
  "SET_DELETE_UPLOADED_RESUME_LOADING";
