import {
  ResumeData,
  ResumeParserState
} from "@/store/modules/resume_parser/interfaces";
import {
  GET_DELETE_UPLOADED_RESUME_LOADING,
  GET_POST_RESUME_LOADING,
  GET_RESUME_LISTING,
  GET_RESUME_LISTING_LOADING,
  GET_RESUME_PARSER_LOADING,
  GET_UPLOADED_RESUMES_ID
} from "@/store/modules/resume_parser/constants";

export default {
  [GET_RESUME_PARSER_LOADING]: (state: ResumeParserState): boolean =>
    state.resume_parser_loading,
  [GET_RESUME_LISTING_LOADING]: (state: ResumeParserState): boolean =>
    state.resume_listing_loading,
  [GET_POST_RESUME_LOADING]: (state: ResumeParserState): boolean =>
    state.post_resume_loading,
  [GET_RESUME_LISTING]: (state: ResumeParserState): ResumeData => state.resumes,
  [GET_UPLOADED_RESUMES_ID]: (state: ResumeParserState): number[] => {
    return state.uploaded_resumes_ids;
  },
  [GET_DELETE_UPLOADED_RESUME_LOADING]: (state: ResumeParserState): boolean =>
    state.remove_resume_loading
};
