import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import { tokenInterceptor, errorInterceptor } from "./utils/interceptor";
import ErrorService from "@/services/ErrorService";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
import InlineSvg from "vue-inline-svg";
Vue.config.productionTip = false;
/**
 * initialize the error interceptor
 * */
errorInterceptor();

//Configure vue error handler to send errors for log
// Vue.config.errorHandler = function (err, vm, info) {
//   ErrorService.onError(err.toString(), "VUE");
// };
/**
 * initiate the axios token interceptor
 * */
tokenInterceptor();
Vue.use(LottieVuePlayer);
Vue.component("inline-svg", InlineSvg);
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
