import {
  ResumeData,
  ResumeParserState
} from "@/store/modules/resume_parser/interfaces";
import {
  DELETE_UPLOADED_RESUME,
  SET_DELETE_UPLOADED_RESUME_LOADING,
  SET_POST_RESUME_LOADING,
  SET_RESUME,
  SET_RESUME_LISTING_LOADING,
  SET_RESUME_PARSER_LOADING,
  SET_UPLOADED_RESUMES_ID
} from "@/store/modules/resume_parser/constants";

export default {
  // Global state loader
  [SET_RESUME_PARSER_LOADING]: (
    state: ResumeParserState,
    payload: boolean
  ): void => {
    state.resume_parser_loading = payload;
  },
  // Loader for resume listing
  [SET_RESUME_LISTING_LOADING]: (
    state: ResumeParserState,
    payload: boolean
  ): void => {
    state.resume_listing_loading = payload;
  },
  // Loader for resume posting
  [SET_POST_RESUME_LOADING]: (
    state: ResumeParserState,
    payload: boolean
  ): void => {
    state.post_resume_loading = payload;
  },
  // Set resumes
  [SET_RESUME]: (state: ResumeParserState, payload: ResumeData): void => {
    state.resumes = payload;
  },
  // Set uploaded resumes ids
  [SET_UPLOADED_RESUMES_ID]: (
    state: ResumeParserState,
    payload: number[]
  ): void => {
    // Updating uploaded resumes ids in store
    state.uploaded_resumes_ids = state.uploaded_resumes_ids.concat(payload);
    // Set uploaded resumes ids in session storage
    const existing_ids = sessionStorage.getItem("uploaded_resumes");
    if (!existing_ids)
      sessionStorage.setItem("uploaded_resumes", JSON.stringify(payload));
    else {
      const ids = sessionStorage.getItem("uploaded_resumes");
      let _ids = JSON.parse(ids as string);
      _ids = _ids.concat(payload);
      sessionStorage.setItem("uploaded_resumes", JSON.stringify(_ids));
    }
  },
  // Delete resume loading
  [SET_DELETE_UPLOADED_RESUME_LOADING]: (
    state: ResumeParserState,
    payload: boolean
  ): void => {
    state.remove_resume_loading = payload;
  },
  // Delete uploaded resumes
  [DELETE_UPLOADED_RESUME]: (
    state: ResumeParserState,
    payload: number
  ): void => {
    // Remove resume from store
    state.resumes.resumes = state.resumes.resumes.filter(
      (resume) => resume.id !== payload
    );
    // Updating uploaded resumes ids
    state.uploaded_resumes_ids = state.uploaded_resumes_ids.filter(
      (id) => id !== payload
    );
    // Updating session storage
    const session_ids = sessionStorage.getItem("uploaded_resumes");
    if (session_ids) {
      let ids = JSON.parse(session_ids);
      ids = ids.filter((id: number) => id !== payload);
      if (ids.length > 0)
        sessionStorage.setItem("uploaded_resumes", JSON.stringify(ids));
      else sessionStorage.removeItem("uploaded_resumes");
    }
  }
};
