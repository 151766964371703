// export const SET_LOADING = "SET_LOADING";
// export const GET_LOADING = "GET_LOADING";
// export const UPLOAD_CV = "UPLOAD_CV";
export const GET_CANCEL_ANALYZING = "GET_CANCEL_ANALYZING";
export const SET_CANCEL_ANALYZING = "SET_CANCEL_ANALYZING";
export const CANCEL_ANALYZING = "CANCEL_ANALYZING";
export const RESET_STATE = "RESET_STATE";

export const SET_CV_ID = "SET_CV_ID";
export const GETTER_CV_ID = "GET_CV_ID";

// Loading
export const SET_CV_SKILLS_LOADING = "SET_CV_SKILLS_LOADING";
export const GET_CV_SKILLS_LOADING = "GET_CV_SKILLS_LOADING";
export const SET_TOP_JOB_SECTORS_LOADING = "SET_TOP_JOB_SECTORS_LOADING";
export const GET_TOP_JOB_SECTORS_LOADING = "GET_TOP_JOB_SECTORS_LOADING";
export const SET_UPSKILL_COURSES_LOADING = "SET_UPSKILL_COURSES_LOADING";
export const GET_UPSKILL_COURSES_LOADING = "GET_UPSKILL_COURSES_LOADING";
export const SET_SEARCH_JOB_LOADING = "SET_SEARCH_JOB_LOADING";
export const GET_SEARCH_JOB_LOADING = "GET_SEARCH_JOB_LOADING";

// Error
export const SET_SKILL_EXTRACTOR_ERROR = "SET_SKILL_EXTRACTOR_ERROR";
export const GET_SKILL_EXTRACTOR_ERROR = "GET_SKILL_EXTRACTOR_ERROR";
export const SET_SKILL_EXTRACTOR_ERROR_MSG = "SET_SKILL_EXTRACTOR_ERROR_MSG";
export const GET_SKILL_EXTRACTOR_ERROR_MSG = "GET_SKILL_EXTRACTOR_ERROR_MSG";

// CV Skill
export const UPLOAD_CV_AND_GET_SKILLS = "UPLOAD_CV_AND_GET_SKILLS";
export const SET_CV_SKILLS = "SET_CV_SKILLS";
export const GETTER_CV_SKILLS = "GETTER_CV_SKILLS";
export const GET_INTERNAL_CV_ID = "GET_INTERNAL_CV_ID";

// Top job sectors
export const SET_TOP_JOB_SECTORS = "SET_TOP_JOB_SECTORS";
export const GET_TOP_JOB_SECTORS = "GET_TOP_JOB_SECTORS";
export const GETTER_TOP_JOB_SECTORS = "GETTER_TOP_JOB_SECTORS";
export const SET_TOP_JOB_ID = "SET_TOP_JOB_ID";
export const GET_TOP_JOB_ID = "GET_TOP_JOB_ID";
// Upskill
export const SET_UPSKILL_COURSES = "SET_UPSKILL_COURSES";
export const GET_UPSKILL_COURSES = "GET_UPSKILL_COURSES";
export const GETTER_UPSKILL_COURSES = "GETTER_UPSKILL_COURSES";

// Search job
export const SEARCH_JOBS = "SEARCH_JOBS";
export const SEARCH_SECTORS = "SEARCH_SECTORS";
export const GET_SEARCHED_JOB_DATA = "GET_SEARCHED_JOB_DATA";
// Search
export const SECTOR_EXIST = "SECTOR_EXIST";

export const JOB_EXIST = "JOB_EXIST";
export const ADD_SECTOR = "ADD_SECTOR";
export const REMOVE_SECTOR = "REMOVE_SECTOR";

export const ADD_TOP_JOB = "ADD_TOP_JOB";
export const REMOVE_TOP_JOB = "REMOVE_TOP_JOB";

export const GET_TOP_JOB_INDEX = "GET_TOP_JOB_INDEX";

export const GET_SECTOR = "GET_SECTOR";
export const GET_TOP_JOB_WITH_INDEX = "GET_TOP_JOB_WITH_INDEX";

export const GET_ANALYZED_RESUMES = "GET_ANALYZED_RESUMES";
export const GETTER_ANALYZED_RESUMES = "GETTER_ANALYZED_RESUMES";
export const SET_ANALYZED_RESUMES = "SET_ANALYZED_RESUMES";
export const RESET_ANALYZED_RESUMES = "RESET_ANALYZED_RESUMES";

export const FETCH_ANALYZED_RESUME = "FETCH_ANALYZED_RESUME";
export const GET_UPLOAD_VIEW_RESUME = "GET_UPLOAD_VIEW_RESUME";
export const SET_UPLOAD_VIEW_RESUME = "SET_UPLOAD_VIEW_RESUME";

// For Action
export const REMOVE_ANALYZED_RESUME = "REMOVE_ANALYZED_RESUME";
// For Mutation
// export const REMOVE_ANALYZED_RESUME = "REMOVE_ANALYZED_RESUME";
