import { RouteConfig } from "vue-router";

export const global_valley_routes: RouteConfig = {
  path: "/global-valley",
  component: () => import("@/views/global_valley/GlobalValleyIndex.vue"),
  children: [
    {
      path: "",
      name: "global-valley-dashboard",
      component: () => import("@/views/global_valley/Dashboard.vue")
    },
    {
      path: "job-parser",
      component: () =>
        import("@/views/global_valley/job_parser/JobParserIndex.vue"),
      children: [
        {
          path: "",
          name: "global-valley-job-listing",
          component: () =>
            import(
              "@/views/global_valley/job_parser/job_listing/JobsListing.vue"
            )
        },
        {
          path: "post-job",
          name: "global-valley-post-job",
          component: () =>
            import("@/views/global_valley/job_parser/post_job/PostJob.vue")
        }
      ]
    },
    {
      path: "resume-parser",
      component: () =>
        import("@/views/global_valley/resume_parser/ResumeParserIndex.vue"),
      children: [
        {
          path: "",
          name: "global-valley-resume-listing",
          component: () =>
            import(
              "@/views/global_valley/resume_parser/resume_listing/ResumeListing.vue"
            )
        },
        {
          path: "post-resume",
          name: "global-valley-post-resume",
          component: () =>
            import(
              "@/views/global_valley/resume_parser/post_resume/PostResume.vue"
            )
        }
      ]
    },
    {
      path: "analyzing",
      name: "global-valley-analyzing",
      component: () => import("@/views/global_valley/AnalyzingFiles.vue")
    }
  ]
};
