import axios, { AxiosResponse } from "axios";
import {
  DELETE_RESUME,
  GET_POSTED_JOBS,
  GET_RESUMES_STATUS,
  GET_UPLOADED_RESUMES,
  POST_JOB,
  POST_RESUMES
} from "@/store/urls/global_valley";
import { ResumeListingActionPayload } from "@/store/modules/resume_parser/interfaces";
import { JobListingActionPayload } from "@/store/modules/job_parser/interfaces";

export const post_resumes = async (
  payload: FormData
): Promise<AxiosResponse> => {
  return await axios
    .post(POST_RESUMES, payload)
    .then((response) => response.data);
};

export const post_job = async (
  payload: FormData | { job_id: number }
): Promise<AxiosResponse> => {
  return await axios.post(POST_JOB, payload).then((response) => response.data);
};

export const get_resumes_status = async (payload: {
  resume_uploaded_ids: number[];
}): Promise<AxiosResponse> => {
  return await axios
    .post(GET_RESUMES_STATUS, payload)
    .then((response) => response.data);
};

export const get_uploaded_resumes = async (
  payload: ResumeListingActionPayload
): Promise<AxiosResponse> => {
  return await axios
    .get(
      `${GET_UPLOADED_RESUMES}/?page_number=${payload.page}&item_per_page=${payload.limit}`
    )
    .then((response) => response.data);
};

export const get_posted_jobs = async (
  payload: JobListingActionPayload
): Promise<AxiosResponse> => {
  return await axios
    .get(
      `${GET_POSTED_JOBS}/?page_number=${payload.page}&item_per_page=${payload.limit}`
    )
    .then((response) => response.data);
};

export const delete_resume = async (
  resume_id: number
): Promise<AxiosResponse> => {
  return await axios
    .patch(`${DELETE_RESUME}/${resume_id}`)
    .then((response) => response.data);
};
