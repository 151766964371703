import { Commit } from "vuex";
import {
  DELETE_UPLOADED_RESUME,
  GET_RESUME_LISTING,
  GET_UPLOADED_RESUMES_STATUS,
  POST_RESUME,
  SET_DELETE_UPLOADED_RESUME_LOADING,
  SET_POST_RESUME_LOADING,
  SET_RESUME,
  SET_RESUME_LISTING_LOADING,
  SET_UPLOADED_RESUMES_ID
} from "@/store/modules/resume_parser/constants";
import {
  Resume,
  ResumeListingActionPayload
} from "@/store/modules/resume_parser/interfaces";
import {
  delete_resume,
  get_resumes_status,
  get_uploaded_resumes,
  post_resumes
} from "@/store/apis/global_valley";
import router from "@/router";
import { SET_ERROR, SET_NOTIFICATION } from "@/store";
export default {
  [GET_RESUME_LISTING]: async (
    { commit }: { commit: Commit },
    payload: ResumeListingActionPayload
  ): Promise<void> => {
    try {
      commit(SET_RESUME_LISTING_LOADING, true);
      let count = 1;
      while (count < 16) {
        const resumes = await get_uploaded_resumes(payload);
        commit(SET_RESUME_LISTING_LOADING, false);
        const obj = {
          count: resumes.data.total,
          resumes: resumes.data.results
        };
        commit(SET_RESUME, obj);
        // Checking cv book link exist
        const in_progress = resumes.data.results.filter(
          (resume: Resume) => resume.status === "in_progress"
        );
        if (in_progress.length === 0) break;
        await new Promise((resolve) => setTimeout(resolve, 15000));
        count += 1;
      }
    } catch (e) {
      commit(SET_ERROR, e, { root: true });
      commit(SET_RESUME_LISTING_LOADING, false);
    }
  },
  [POST_RESUME]: async (
    { commit }: { commit: Commit },
    payload: FormData
  ): Promise<void> => {
    try {
      commit(SET_POST_RESUME_LOADING, true);
      await router.push({
        name: "global-valley-analyzing"
      });
      const response = await post_resumes(payload);
      commit(SET_UPLOADED_RESUMES_ID, response.data);
      commit(SET_POST_RESUME_LOADING, false);
    } catch (e) {
      commit(SET_POST_RESUME_LOADING, false);
      await router.push({
        name: "global-valley-resume-listing"
      });
      commit(SET_ERROR, e, { root: true });
    }
  },
  [GET_UPLOADED_RESUMES_STATUS]: async (
    { commit }: { commit: Commit },
    payload: number[]
  ): Promise<boolean> => {
    try {
      const obj = {
        resume_uploaded_ids: payload
      };
      const response: any = await get_resumes_status(obj);
      if (!response.data)
        commit(SET_NOTIFICATION, response.message, { root: true });
      return response.data;
    } catch (e) {
      commit(SET_ERROR, e, { root: true });
      return false;
    }
  },
  [DELETE_UPLOADED_RESUME]: async (
    { commit }: { commit: Commit },
    payload: number
  ): Promise<void> => {
    try {
      commit(SET_DELETE_UPLOADED_RESUME_LOADING, true);
      const response = await delete_resume(payload);
      commit(DELETE_UPLOADED_RESUME, payload);
      commit(SET_NOTIFICATION, response.data.message, { root: true });
      commit(SET_DELETE_UPLOADED_RESUME_LOADING, false);
    } catch (e) {
      commit(SET_ERROR, e, { root: true });
    }
  }
};
