import { JobParserState } from "@/store/modules/job_parser/interfaces";
import getters from "@/store/modules/job_parser/getters";
import mutations from "@/store/modules/job_parser/mutations";
import actions from "@/store/modules/job_parser/actions";
export default {
  namespaced: true,
  state: (): JobParserState => ({
    job_parser_loading: false,
    job_listing_loading: false,
    post_job_loading: false,
    reanalysis_job_loading: false,
    reanalysis_job_id: [],
    jobs: {
      count: 0,
      jobs: []
    }
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
