import {
  CV_JOB_ANALYSIS_PAYLOAD,
  CV_JOB_LOADING,
  FETCH_SKILLS_GROUP_LOADING,
  GET_COMPARE_GAP_SCORE,
  GET_COMPARE_RESPONSE_GAP
} from "@/store/modules/cv_job_matching/constants";
import {
  CvJobAnalysis,
  CvJobMatchingState
} from "@/store/modules/cv_job_matching/interfaces";
import { SkillGroup } from "@/interfaces/responses/compare_gap";

export default {
  /**
   * Get state loading
   * @param state
   */
  [CV_JOB_LOADING]: (state: CvJobMatchingState): boolean => state.loading,
  /**
   * Get fetching skill group loading
   * @param state
   */
  [FETCH_SKILLS_GROUP_LOADING]: (state: CvJobMatchingState): boolean =>
    state.skill_group_loading,
  [CV_JOB_ANALYSIS_PAYLOAD]: (state: CvJobMatchingState): CvJobAnalysis =>
    state.cv_job_analysis,
  [GET_COMPARE_GAP_SCORE]: (state: CvJobMatchingState): null | number => {
    const val = state.cv_job_analysis.compare_gap_response;
    if (!val || !val.score) return null;
    return val.score;
  },
  [GET_COMPARE_RESPONSE_GAP]: (
    state: CvJobMatchingState
  ): null | SkillGroup[] => {
    const val = state.cv_job_analysis.compare_gap_response;
    if (!val || val.gap.length <= 0) return null;
    return val.gap;
  }
};
