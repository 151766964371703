import Vue from "vue";
import Vuex from "vuex";
import { auth } from "@/store/modules/auth";
import ErrorService from "@/services/ErrorService";
import { skill_extractor_old } from "@/store/modules/skill_extractor_old";
import skill_extractor from "@/store/modules/skill_extractor";
import job_parser from "@/store/modules/job_parser";
import resume_parser from "@/store/modules/resume_parser";
import cv_job_matching from "@/store/modules/cv_job_matching";

export const SET_ERROR = "SET_ERROR";
export const GET_ERROR = "GET_ERROR";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const GET_NOTIFICATION = "GET_NOTIFICATION";

Vue.use(Vuex);

export interface RootState {
  error: string | null;
  notification: string | null;
}

export default new Vuex.Store({
  /**
   * vuex store modules
   * */
  modules: {
    auth,
    skill_extractor_old,
    skill_extractor,
    job_parser,
    resume_parser,
    cv_job_matching
  },
  /**
   * vuex state variable
   * */
  state: {
    error: null,
    notification: null
  },
  mutations: {
    /**
     * vuex function for initiating the error log api as well as display error in snack bar
     * */
    [SET_ERROR]: (state: RootState, data: any): void => {
      if (data) {
        //send api error to error log service
        if (data.response && data.response.request) {
          const errorText = `URL:${data.response.request.responseURL} RespText:${data.response.data.message} RespCode:${data.response.status}`;
          ErrorService.onError(errorText, "API");
        } else {
          //Do Nothing
        }
        if (typeof data === "string") {
          state.error = data;
        } else if ("response" in data && data.response) {
          state.error = data.response.data.message;
        } else {
          state.error = "Some error has occurred. Please try again later.";
        }
      } else {
        state.error = null;
      }
    },
    /**
     * display notification in snackbar
     * */
    [SET_NOTIFICATION]: (state: RootState, data: string | null): void => {
      state.notification = data;
    }
  },
  actions: {},
  getters: {
    [GET_ERROR]: (state: RootState): any => state.error,
    [GET_NOTIFICATION]: (state: RootState): any => state.notification
  }
});
