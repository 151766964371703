import axios, { AxiosResponse } from "axios";
import {
  CV_FILE_UPLOAD,
  CV_TEXT_UPLOAD,
  CV_TOP_JOBS,
  GET_CV_SKILLS,
  SEARCH_JOB,
  JOB_COMPARE,
  CV_COURSES,
  UPLOAD_FILE,
  UPLOAD_RESUME_FILE,
  UPLOAD_RESUME_TEXT,
  GET_EXTRACTED_CV_SKILLS,
  SECTOR_TOP_JOBS,
  UPSKILL_COURSES,
  JOB_SEARCH,
  SECTOR_SEARCH,
  GET_SEARCHED_JOB_DATA,
  GET_ANALYZED_RESUMES,
  REMOVE_ANALYZED_RESUME,
  SEARCH_RESUME,
  POST_NEW_JOB,
  GET_ANALYZED_COMPARISONS,
  FETCH_SKILL_GAP_LIST
} from "../urls";
import { SkillGapListPayload } from "@/store/modules/cv_job_matching/interfaces";
/**
 * function to upload cv text
 * */
export const upload_cv_text = async (text: string): Promise<AxiosResponse> => {
  return await axios
    .post(CV_TEXT_UPLOAD, {
      content: text
    })
    .then((response) => response.data);
};

/**
 * function to upload file
 * */
export const upload_file = async (data: any): Promise<AxiosResponse> => {
  return await axios.post(UPLOAD_FILE, data).then((response) => response.data);
};
/**
 * function to upload cv file
 * */
export const upload_cv_file = async (file: any): Promise<AxiosResponse> => {
  return await axios
    .post(CV_FILE_UPLOAD, {
      cvFile: file
    })
    .then((response) => response.data);
};

/**
 * function to get cv skills
 * */
export const get_cv_skills = async (id: string): Promise<AxiosResponse> => {
  return await axios.get(`${GET_CV_SKILLS}/${id}`).then((response) => response);
};

/**
 * function to get cv skills
 * */
export const cv_top_jobs = async (id: string): Promise<AxiosResponse> => {
  return await axios
    .post(CV_TOP_JOBS, {
      response_id: id,
      total_job: 3
    })
    .then((response) => response.data);
};

/**
 * function to get cv skills
 * */
export const get_cv_top_jobs = async (id: string): Promise<AxiosResponse> => {
  return await axios.get(`${CV_TOP_JOBS}/${id}`).then((response) => response);
};

/**
 * function to cv upskill courses
 * */
export const cv_upskill_courses = async (
  payload: any
): Promise<AxiosResponse> => {
  return await axios
    .post(CV_COURSES, payload)
    .then((response) => response.data);
};

/**
 * function to get cv upskill courses
 * */
export const get_cv_upskill_courses = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.get(`${CV_COURSES}/${id}`).then((response) => response);
};

/**
 * function to job search
 * */
export const search_job = async (title: string): Promise<AxiosResponse> => {
  return await axios.get(`${SEARCH_JOB}${title}`).then((response) => response);
};

/**
 * function to job compare POST
 * */
export const compare_job_post = async (
  payload: any
): Promise<AxiosResponse> => {
  return await axios
    .post(`${JOB_COMPARE}`, {
      response_id: payload.response_id,
      job_index: payload.job_index
    })
    .then((response) => response.data);
};

/**
 * function to job compare get
 * */
export const compare_job_get = async (
  compare_id: string
): Promise<AxiosResponse> => {
  return await axios
    .get(`${JOB_COMPARE}/${compare_id}`)
    .then((response) => response);
};

// New API endpoints
export const upload_resume_file = async (data: any): Promise<AxiosResponse> => {
  return await axios
    .post(UPLOAD_RESUME_FILE, data)
    .then((response) => response.data);
};

export const upload_resume_text = async (
  text: string
): Promise<AxiosResponse> => {
  return await axios
    .post(UPLOAD_RESUME_TEXT, {
      content: text
    })
    .then((response) => response.data);
};

export const get_extracted_cv_skills = async (
  response_id: string
): Promise<AxiosResponse> => {
  return await axios
    .get(`${GET_EXTRACTED_CV_SKILLS}/${response_id}`)
    .then((response) => response);
};

export const post_sector_top_jobs = async (
  response_id: string
): Promise<AxiosResponse> => {
  return await axios
    .post(SECTOR_TOP_JOBS, {
      response_id: response_id,
      total_job: 3
    })
    .then((response) => response.data);
};

export const get_sector_top_jobs = async (
  topjob_id: string
): Promise<AxiosResponse> => {
  return await axios
    .get(`${SECTOR_TOP_JOBS}/${topjob_id}`)
    .then((response) => response);
};

export const post_upskill_courses = async (
  payload: any
): Promise<AxiosResponse> => {
  return await axios
    .post(UPSKILL_COURSES, payload)
    .then((response) => response.data);
};

export const get_upskill_courses = async (
  course_id: string
): Promise<AxiosResponse> => {
  return await axios
    .get(`${UPSKILL_COURSES}/${course_id}`)
    .then((response) => response);
};

export const job_search = async (job_name: string): Promise<AxiosResponse> => {
  return await axios
    .get(`${JOB_SEARCH}${job_name}`)
    .then((response) => response);
};

export const sector_search = async (
  sector_name: string
): Promise<AxiosResponse> => {
  return await axios
    .get(`${SECTOR_SEARCH}${sector_name}`)
    .then((response) => response);
};

export const post_search_job = async (payload: any): Promise<AxiosResponse> => {
  return await axios
    .post(GET_SEARCHED_JOB_DATA, payload)
    .then((response) => response.data);
};

export const get_search_job = async (
  compare_id: any
): Promise<AxiosResponse> => {
  return await axios
    .get(`${GET_SEARCHED_JOB_DATA}/${compare_id}`)
    .then((response) => response);
};

export const get_analyzed_resumes = async (
  page_number: number,
  item_per_page: number,
  top_job = true
): Promise<AxiosResponse> => {
  return await axios
    .get(
      `${GET_ANALYZED_RESUMES}/?page_number=${page_number}&item_per_page=${item_per_page}&top_job=${top_job}`
    )
    .then((response) => response.data);
};

export const remove_analyzed_resumes = async (
  response_id: string
): Promise<AxiosResponse> => {
  return await axios
    .patch(`${REMOVE_ANALYZED_RESUME}/${response_id}`)
    .then((response) => response.data.data);
};

export const search_resume = async (
  cv_name: string
): Promise<AxiosResponse> => {
  return await axios
    .get(`${SEARCH_RESUME}${cv_name}`)
    .then((response) => response);
};

export const post_new_job = async (
  payload: FormData
): Promise<AxiosResponse> => {
  return axios.post(POST_NEW_JOB, payload);
};

export const get_analyzed_comparisons = async (
  page_number: number,
  item_per_page: number
): Promise<AxiosResponse> => {
  return await axios
    .get(
      `${GET_ANALYZED_COMPARISONS}/?page_number=${page_number}&item_per_page=${item_per_page}`
    )
    .then((response) => response.data);
};

export const skill_gap_list = async (
  payload: SkillGapListPayload
): Promise<AxiosResponse> => {
  return await axios.post(FETCH_SKILL_GAP_LIST, payload);
};
