import getters from "@/store/modules/cv_job_matching/getters";
import mutations from "@/store/modules/cv_job_matching/mutations";
import actions from "@/store/modules/cv_job_matching/actions";
import { CvJobMatchingState } from "@/store/modules/cv_job_matching/interfaces";
export default {
  namespaced: true,
  state: (): CvJobMatchingState => ({
    loading: false,
    skill_group_loading: false,
    cv_job_analysis: {
      cv_loading: false,
      job_loading: false,
      compare_gap_loading: false,
      cv_file: null,
      job_file: null,
      job_link: null,
      searched_cv: null,
      cv_file_response: null,
      job_response: null,
      compare_gap_response: null,
      listing: null,
      fetching_required: true
    }
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
