import {
  SET_CV_ID,
  SET_CV_SKILLS,
  SET_CV_SKILLS_LOADING,
  SET_TOP_JOB_ID,
  SET_TOP_JOB_SECTORS,
  SET_SKILL_EXTRACTOR_ERROR,
  SET_SKILL_EXTRACTOR_ERROR_MSG,
  SET_TOP_JOB_SECTORS_LOADING,
  SET_UPSKILL_COURSES,
  SET_UPSKILL_COURSES_LOADING,
  SET_CANCEL_ANALYZING,
  ADD_SECTOR,
  REMOVE_SECTOR,
  ADD_TOP_JOB,
  REMOVE_TOP_JOB,
  SET_SEARCH_JOB_LOADING,
  SET_ANALYZED_RESUMES,
  RESET_ANALYZED_RESUMES,
  SET_UPLOAD_VIEW_RESUME,
  REMOVE_ANALYZED_RESUME
} from "@/store/modules/skill_extractor/constants";
import { SkillExtractorState } from "@/store/modules/skill_extractor/interfaces";

export default {
  // Loading mutations
  // [SET_LOADING]: (state: SkillExtractorState, payload: boolean): void => {
  //   state.loading = payload;
  // },
  [SET_CV_SKILLS_LOADING]: (
    state: SkillExtractorState,
    payload: boolean
  ): void => {
    state.cv_skill_loading = payload;
  },
  [SET_TOP_JOB_SECTORS_LOADING]: (
    state: SkillExtractorState,
    payload: boolean
  ): void => {
    state.top_job_sectors_loading = payload;
  },
  [SET_UPSKILL_COURSES_LOADING]: (
    state: SkillExtractorState,
    payload: boolean
  ): void => {
    state.upskill_courses_loading = payload;
  },
  [SET_SEARCH_JOB_LOADING]: (
    state: SkillExtractorState,
    payload: boolean
  ): void => {
    state.search_job_loading = payload;
  },
  // Error Mutations
  [SET_SKILL_EXTRACTOR_ERROR]: (
    state: SkillExtractorState,
    payload: boolean
  ): void => {
    state.skill_extractor_error = payload;
  },
  [SET_SKILL_EXTRACTOR_ERROR_MSG]: (
    state: SkillExtractorState,
    payload: string
  ): void => {
    state.skill_extractor_error_msg = payload;
  },
  [SET_CV_ID]: (state: SkillExtractorState, payload: string): void => {
    state.cv_id = payload;
  },
  [SET_CV_SKILLS]: (state: SkillExtractorState, payload: any): void => {
    state.cv_skills = payload;
  },
  [SET_TOP_JOB_SECTORS]: (state: SkillExtractorState, payload: any): void => {
    state.top_job_sectors = payload;
  },
  [SET_TOP_JOB_ID]: (state: SkillExtractorState, payload: string): void => {
    state.top_job_id = payload;
  },
  [SET_UPSKILL_COURSES]: (state: SkillExtractorState, payload: any): void => {
    state.upskill_courses = payload;
  },
  [SET_CANCEL_ANALYZING]: (
    state: SkillExtractorState,
    payload: boolean
  ): void => {
    state.cancel_analyzing = payload;
  },
  [ADD_SECTOR]: (state: SkillExtractorState, payload: any): void => {
    state.top_job_sectors.topjob_response.sectors.push(payload);
  },
  [REMOVE_SECTOR]: (state: SkillExtractorState, sector: string): void => {
    const sector_name = sector.toLowerCase().trim();
    state.top_job_sectors.topjob_response.sectors =
      state.top_job_sectors.topjob_response.sectors.filter(
        (val: any) => val.name.toLowerCase().trim() !== sector_name
      );
  },
  [ADD_TOP_JOB]: (state: SkillExtractorState, payload: any): void => {
    const sector_name = payload.sector_name.toLowerCase().trim();
    // Find sector index
    const index = state.top_job_sectors.topjob_response.sectors.findIndex(
      (val: any) => val.name.toLowerCase().trim() === sector_name
    );
    // If sector found add new top job
    if (index !== -1) {
      state.top_job_sectors.topjob_response.sectors[index].top_jobs.push(
        payload.top_job
      );
    }
  },
  [REMOVE_TOP_JOB]: (state: SkillExtractorState, payload: any): void => {
    const sector_name = payload.sector_name.toLowerCase().trim();
    // Find sector index
    const index = state.top_job_sectors.topjob_response.sectors.findIndex(
      (val: any) => val.name.toLowerCase().trim() === sector_name
    );
    // If sector found remove jop job
    if (index !== -1) {
      state.top_job_sectors.topjob_response.sectors[index].top_jobs =
        state.top_job_sectors.topjob_response.sectors[index].top_jobs.filter(
          (val: any) =>
            val.job_id !== payload.top_job.job_id &&
            val.job_title.toLowerCase().trim() !==
              payload.top_job.job_title.toLowerCase().trim()
        );
    }
  },
  [SET_ANALYZED_RESUMES]: (
    state: SkillExtractorState,
    payload: Array<unknown>
  ): void => {
    state.analyzed_resumes = payload;
  },
  [RESET_ANALYZED_RESUMES]: (state: SkillExtractorState): void => {
    state.analyzed_resumes.results = [];
  },
  [SET_UPLOAD_VIEW_RESUME]: (
    state: SkillExtractorState,
    payload: boolean
  ): void => {
    state.upload_view_resume = payload;
  },
  [REMOVE_ANALYZED_RESUME]: (
    state: SkillExtractorState,
    upload_response_id: string
  ) => {
    state.analyzed_resumes.results = state.analyzed_resumes.results.filter(
      (val: any) => val.upload_response_id !== upload_response_id
    );
    state.analyzed_resumes.total -= 1;
  }
};
