import { register, login, get_roles, verify_email } from "@/store/apis/user";
import { Commit } from "vuex";
import router from "@/router";

export const SET_LOADING = "SET_LOADING";
export const ACTION_REGISTER = "ACTION_REGISTER";
export const ACTION_VERIFY_EMAIL = "ACTION_VERIFY_EMAIL";
export const ACTION_LOGIN = "ACTION_LOGIN";
export const ACTION_LOGOUT = "ACTION_LOGOUT";
export const SET_USER = "SET_USER";
export const GETTER_LOADING = "GETTER_LOADING";
export const GETTER_IS_AUTHENTICATED = "GETTER_IS_AUTHENTICATED";
export const SET_ERROR = "SET_ERROR";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const GET_USER_DETAILS = "GET_USER_DETAILS";

export const LOG_OUT = "LOG_OUT";

export const GET_ROLES = "GET_ROLES";
export const GETTER_ROLES = "GETTER_ROLES";
export const SET_ROLES = "SET_ROLES";

export interface User {
  isAuthenticated: boolean;
  token: string;
  loading: boolean;
  roles: any;
  user: any;
}

export interface RegisterForm {
  name: string;
  email: string;
  password: string;
  repeat_password: string;
  contact_number: string;
  agreed: boolean;
}

export interface LoginForm {
  email: string;
  password: string;
}
export const auth = {
  namespaced: true,
  /**
   * vuex state variable
   * */
  state: (): User => ({
    isAuthenticated: !!localStorage.getItem("token"),
    token: localStorage.getItem("token") || "",
    loading: false,
    roles: null,
    user: localStorage.getItem("user")
  }),
  mutations: {
    [SET_LOADING]: (state: User, payload: boolean): void => {
      state.loading = payload;
    },
    /**
     * vuex handle login business logic inside store
     * */
    [SET_USER]: (state: User, payload: any): void => {
      state.isAuthenticated = true;
      localStorage.setItem("token", payload.token);
      localStorage.setItem("user", JSON.stringify(payload.user));
      state.token = <string>localStorage.getItem("token");
      state.user = localStorage.getItem("user");
    },
    /**
     * vuex handle logout business logic inside store
     * */
    [LOG_OUT]: (state: User): void => {
      state.isAuthenticated = false;
      state.token = "";
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      state.user = null;
    },
    /**
     * set user roles
     * */
    [SET_ROLES]: (state: User, payload: any): void => {
      state.roles = payload;
    }
  },
  actions: {
    [ACTION_LOGIN]: async (
      {
        commit
      }: {
        commit: Commit;
      },
      payload: LoginForm
    ): Promise<void> => {
      commit(SET_LOADING, true);
      commit(SET_ERROR, null, { root: true });
      try {
        const response = await login(payload);
        commit(SET_USER, response.data);
        commit(SET_LOADING, false);
        if (response.data.user.company_id === 20)
          await router.push({
            name: "global-valley-dashboard"
          });
        else if (response.data.user.email === "info@hcms.ai")
          await router.push("organization-dashboard");
        else await router.push("dashboard");
      } catch (e) {
        commit(SET_LOADING, false);
        commit(SET_ERROR, e, { root: true });
      }
    },
    [ACTION_REGISTER]: async (
      {
        commit
      }: {
        commit: Commit;
      },
      payload: RegisterForm
    ): Promise<void> => {
      commit(SET_LOADING, true);
      commit(SET_ERROR, null, { root: true });
      commit(SET_NOTIFICATION, null, { root: true });
      try {
        const response = await register(payload);
        commit(SET_LOADING, false);
        commit(SET_NOTIFICATION, response.message, { root: true });
        await router.push("/");
      } catch (e) {
        commit(SET_LOADING, false);
        commit(SET_ERROR, e, { root: true });
      }
    },
    [ACTION_LOGOUT]: async ({ commit }: { commit: Commit }): Promise<void> => {
      commit(LOG_OUT);
      await router.push("/");
    },
    [ACTION_VERIFY_EMAIL]: async (
      {
        commit
      }: {
        commit: Commit;
      },
      token: string
    ): Promise<void> => {
      commit(SET_LOADING, true);
      commit(SET_ERROR, null, { root: true });
      try {
        const res = await verify_email(token);
        commit(SET_LOADING, false);
        return res;
      } catch (e) {
        commit(SET_LOADING, false);
        commit(SET_ERROR, e, { root: true });
      }
    },
    [GET_ROLES]: async ({ commit }: { commit: Commit }): Promise<void> => {
      commit(SET_LOADING, true);
      commit(SET_ERROR, null, { root: true });
      try {
        const response = await get_roles();
        commit(SET_ROLES, response.data);
        commit(SET_LOADING, false);
      } catch (e) {
        commit(SET_LOADING, false);
        commit(SET_ERROR, e, { root: true });
      }
    }
  },
  getters: {
    [GETTER_LOADING]: (state: User): any => {
      return state.loading;
    },
    [GETTER_ROLES]: (state: User): any => {
      return state.roles;
    },
    [GETTER_IS_AUTHENTICATED]: (state: User): any => {
      return state.isAuthenticated;
    },
    [GET_USER_DETAILS]: (state: User): any => {
      return JSON.parse(state.user);
    }
  }
};
