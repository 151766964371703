import { RouteConfig } from "vue-router";

export const skill_extractor_routes: RouteConfig = {
  path: "/skill-extractor",
  component: () => import("@/views/skill_extractor/SkillExtractorIndex.vue"),
  children: [
    {
      path: "",
      name: "skill-extractor",
      component: () =>
        import("@/views/skill_extractor/SkillExtractorResumesList.vue"),
      meta: { transition: "slide-left" }
    },
    {
      path: "resume-analysis",
      name: "resume-analysis",
      component: () => import("@/views/skill_extractor/SkillExtractor.vue"),
      meta: { transition: "slide-right" }
    }
  ]
};
