import axios, { AxiosResponse } from "axios";
import {
  REGISTER,
  LOGIN,
  GET_ROLES,
  VERIFY_EMAIL,
  FETCH_SKILLS_GROUP
} from "../urls";
import { RegisterForm, LoginForm } from "@/store/modules/auth";
import { FetchSkillsGroupActionPayload } from "@/store/modules/cv_job_matching/interfaces";
/**
 * function for user login
 * */
export const login = async (payload: LoginForm): Promise<AxiosResponse> => {
  return await axios.post(LOGIN, payload).then((response) => response.data);
};

/**
 * function for user register
 * */
export const register = async (payload: RegisterForm) => {
  return await axios.post(REGISTER, payload).then((response) => response.data);
};

/**
 * function to get user roles
 * */
export const get_roles = async (): Promise<AxiosResponse> => {
  return await axios.get(GET_ROLES).then((response) => response.data);
};

/**
 * function for user register
 * */
export const verify_email = async (token: string) => {
  return await axios
    .get(`${VERIFY_EMAIL}?verify_token=${token}`)
    .then((response) => response.data);
};

export const fetch_skills_group = async (
  payload: FetchSkillsGroupActionPayload
): Promise<AxiosResponse> => {
  return await axios.post(FETCH_SKILLS_GROUP, payload);
};
