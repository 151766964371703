import { BASE_URL } from "@/store/urls";

const base_url = BASE_URL + "user/POC/";

export const POST_RESUMES = base_url + "upload-resumes";
export const POST_JOB = base_url + "post-job";
export const GET_RESUMES_STATUS = base_url + "upload-status";

export const GET_UPLOADED_RESUMES = base_url + "uploaded-resumes";
export const GET_POSTED_JOBS = base_url + "posted-jobs";
export const DELETE_RESUME = base_url + "delete-resume";
