import {
  ADD_REANALYSIS_JOB_ID,
  GET_JOBS_LISTING,
  POST_JOB,
  REANALYSIS_JOB,
  REMOVE_REANALYSIS_JOB_ID,
  SET_JOBS,
  SET_JOBS_LISTING_LOADING,
  SET_POST_JOB_LOADING,
  SET_REANALYSIS_JOB_LOADING,
  UPDATE_JOBS_VALUE
} from "@/store/modules/job_parser/constants";
import {
  Job,
  JobListingActionPayload
} from "@/store/modules/job_parser/interfaces";
import { Commit } from "vuex";
import { get_posted_jobs, post_job } from "@/store/apis/global_valley";
import { SET_ERROR } from "@/store";
import router from "@/router";

export default {
  [GET_JOBS_LISTING]: async (
    { commit }: { commit: Commit },
    payload: JobListingActionPayload
  ): Promise<void> => {
    try {
      commit(SET_JOBS_LISTING_LOADING, true);
      let count = 1;
      while (count < 16) {
        const jobs = await get_posted_jobs(payload);
        commit(SET_JOBS_LISTING_LOADING, false);
        const obj = {
          count: jobs.data.total,
          jobs: jobs.data.results
        };
        commit(SET_JOBS, obj);
        // Checking cv book link exist
        const not_exist = jobs.data.results.filter(
          (job: Job) => !job.resume_book_link
        );
        if (not_exist.length === 0) break;
        await new Promise((resolve) => setTimeout(resolve, 15000));
        count += 1;
      }
    } catch (e) {
      commit(SET_ERROR, e, { root: true });
      commit(SET_JOBS_LISTING_LOADING, false);
    }
  },
  [POST_JOB]: async (
    { commit }: { commit: Commit },
    payload: FormData
  ): Promise<void> => {
    try {
      commit(SET_POST_JOB_LOADING, true);
      await router.push({
        name: "global-valley-analyzing"
      });
      const response = await post_job(payload);
      window.open(response.data, "_blank");
      commit(SET_POST_JOB_LOADING, false);
      const _payload: JobListingActionPayload = {
        page: 0,
        limit: 10
      };
      const jobs = await get_posted_jobs(_payload);
      const jobs_obj = {
        count: jobs.data.total,
        jobs: jobs.data.results
      };
      commit(SET_JOBS, jobs_obj);
    } catch (e) {
      commit(SET_POST_JOB_LOADING, false);
      commit(SET_ERROR, e, { root: true });
      await router.push({
        name: "global-valley-job-listing"
      });
    }
  },
  [REANALYSIS_JOB]: async (
    { commit }: { commit: Commit },
    payload: number
  ): Promise<void> => {
    try {
      commit(SET_REANALYSIS_JOB_LOADING, true);
      commit(ADD_REANALYSIS_JOB_ID, payload);
      const obj = {
        job_id: payload
      };
      const response = await post_job(obj);
      window.open(response.data, "_blank");
      commit(SET_REANALYSIS_JOB_LOADING, false);
      commit(REMOVE_REANALYSIS_JOB_ID, payload);
      commit(UPDATE_JOBS_VALUE, {
        jid: payload,
        link: response.data
      });
    } catch (e) {
      commit(SET_REANALYSIS_JOB_LOADING, false);
      commit(REMOVE_REANALYSIS_JOB_ID, payload);
      commit(SET_ERROR, e, { root: true });
      await router.push({
        name: "global-valley-job-listing"
      });
    }
  }
};
