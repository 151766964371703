import getters from "@/store/modules/skill_extractor/getters";
import { SkillExtractorState } from "@/store/modules/skill_extractor/interfaces";
import mutations from "@/store/modules/skill_extractor/mutations";
import actions from "@/store/modules/skill_extractor/actions";
export default {
  namespaced: true,
  state: (): SkillExtractorState => ({
    // loading: false,
    cv_skill_loading: false,
    top_job_sectors_loading: false,
    upskill_courses_loading: false,
    search_job_loading: false,
    skill_extractor_error: false,
    skill_extractor_error_msg: "",
    cv_id: "",
    cv_skills: "",
    top_job_sectors: "",
    top_job_id: "",
    upskill_courses: "",
    cancel_analyzing: false,
    analyzed_resumes: {},
    upload_view_resume: false // false: display upload paste card, true: other processing
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
