import {
  GETTER_CV_ID,
  // GET_LOADING,
  GETTER_TOP_JOB_SECTORS,
  GETTER_CV_SKILLS,
  GET_CV_SKILLS_LOADING,
  GET_TOP_JOB_SECTORS_LOADING,
  GET_SKILL_EXTRACTOR_ERROR,
  GET_SKILL_EXTRACTOR_ERROR_MSG,
  GET_UPSKILL_COURSES_LOADING,
  GETTER_UPSKILL_COURSES,
  GET_CANCEL_ANALYZING,
  GET_INTERNAL_CV_ID,
  GET_SEARCH_JOB_LOADING,
  GET_TOP_JOB_INDEX,
  GET_SECTOR,
  GET_TOP_JOB_WITH_INDEX,
  GETTER_ANALYZED_RESUMES,
  GET_ANALYZED_RESUMES,
  GET_UPLOAD_VIEW_RESUME
} from "@/store/modules/skill_extractor/constants";
import { SkillExtractorState } from "@/store/modules/skill_extractor/interfaces";

export default {
  // Loading Getters
  // [GET_LOADING]: (state: SkillExtractorState): boolean => state.loading,
  [GET_CV_SKILLS_LOADING]: (state: SkillExtractorState): boolean =>
    state.cv_skill_loading,
  [GET_TOP_JOB_SECTORS_LOADING]: (state: SkillExtractorState): boolean =>
    state.top_job_sectors_loading,
  [GET_UPSKILL_COURSES_LOADING]: (state: SkillExtractorState): boolean =>
    state.upskill_courses_loading,
  [GET_SEARCH_JOB_LOADING]: (state: SkillExtractorState): boolean =>
    state.search_job_loading,
  // Error Getters
  [GET_SKILL_EXTRACTOR_ERROR]: (state: SkillExtractorState): boolean =>
    state.skill_extractor_error,
  [GET_SKILL_EXTRACTOR_ERROR_MSG]: (state: SkillExtractorState): string =>
    state.skill_extractor_error_msg,
  [GETTER_CV_ID]: (state: SkillExtractorState): string => state.cv_id,
  [GETTER_CV_SKILLS]: (state: SkillExtractorState): any =>
    state.cv_skills ? state.cv_skills.upload_response.skills : [],
  [GETTER_TOP_JOB_SECTORS]: (state: SkillExtractorState): any =>
    state.top_job_sectors && state.top_job_sectors.topjob_response
      ? state.top_job_sectors.topjob_response.sectors
      : [],
  [GETTER_UPSKILL_COURSES]: (state: SkillExtractorState): any =>
    state.upskill_courses,
  [GET_CANCEL_ANALYZING]: (state: SkillExtractorState): boolean =>
    state.cancel_analyzing,
  [GET_INTERNAL_CV_ID]: (state: SkillExtractorState): string =>
    state.cv_skills.id,
  [GET_TOP_JOB_INDEX]:
    (state: SkillExtractorState) =>
    (sector: string, job_id: number, job: string) => {
      const sector_name = sector.toLowerCase().trim();
      const sector_details = state.top_job_sectors.topjob_response.sectors.find(
        (val: any) => val.name.toLowerCase().trim() === sector_name
      );
      if (sector_details) {
        const job_name = job.toLowerCase().trim();
        const top_job_index = sector_details.top_jobs.findIndex(
          (val: any) =>
            val.job_id === job_id &&
            val.job_title.toLowerCase().trim() === job_name
        );
        return top_job_index;
      }
    },
  [GET_SECTOR]: (state: SkillExtractorState) => (sector: string) => {
    const sector_name = sector.toLowerCase().trim();
    const sector_index =
      state.top_job_sectors.topjob_response.sectors.findIndex(
        (val: any) => val.name.toLowerCase().trim() === sector_name
      );
    const sector_details =
      state.top_job_sectors.topjob_response.sectors[sector_index];
    return {
      index: sector_index,
      sector: sector_details
    };
  },
  [GET_TOP_JOB_WITH_INDEX]:
    (state: SkillExtractorState) => (index: number, sector_name: string) => {
      const sector = state.top_job_sectors.topjob_response.sectors.find(
        (val: any) =>
          val.name.toLowerCase().trim() === sector_name.toLowerCase().trim()
      );
      return sector.top_jobs[index];
    },
  [GETTER_ANALYZED_RESUMES]: (state: SkillExtractorState): Array<unknown> =>
    state.analyzed_resumes,
  [GET_UPLOAD_VIEW_RESUME]: (state: SkillExtractorState): boolean =>
    state.upload_view_resume
};
