export const CV_JOB_LOADING = "CV_JOB_LOADING";

export const AUTOCOMPLETE_CV_SEARCH = "AUTOCOMPLETE_CV_SEARCH";

export const FETCH_SKILLS_GROUP = "FETCH_SKILLS_GROUP";
export const FETCH_SKILLS_GROUP_LOADING = "FETCH_SKILLS_GROUP_LOADING";

export const CV_JOB_ANALYSIS_PAYLOAD = "CV_JOB_ANALYSIS_PAYLOAD";

export const UPLOAD_RESUME_AND_FETCH_SKILLS = "UPLOAD_RESUME_AND_FETCH_SKILLS";

export const POST_NEW_JOB = "POST_NEW_JOB";

export const FETCH_COMPARE_GAP = "FETCH_COMPARE_GAP";

export const GET_COMPARE_GAP_SCORE = "GET_COMPARE_GAP_SCORE";

export const GET_COMPARE_RESPONSE_GAP = "GET_COMPARE_RESPONSE_GAP";

export const ANALYZED_COMPARISONS = "ANALYZED_COMPARISONS";

export const SET_LISTING = "SET_LISTING";

export const SKILL_GAP_LIST = "SKILL_GAP_LIST";
